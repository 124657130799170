@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
/* FONT FACE */

@font-face {
  font-family: "youngregular";
  src: url("fonts/young/young-webfont.woff2") format("woff2"),
    url("fonts/young/young-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* *********** -- MOBILE DEFAULTS -- *********** */

h1, h2, h3 {
  font-family: 'Oswald', sans-serif;
} 

.btn-primary a{
  color: white;
}

.font-family-young {
  font-family: "Young", Verdana, sans-serif;
}

p{
  font-family: "Young", Verdana, sans-serif;
}

body {
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #000;
  font-family: "Open Sans", sans-serif;
}

button {
  color: #fff;
  text-transform: uppercase;
  font-size: 19px;
  line-height: 24px;
  background-color: #4abce5;
  border-radius: 10px 10px 10px 10px;
  border: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
}

button:focus {
  border: 1px solid #e3e3e3;
  box-shadow: 1.5px 2px 5px rgba(0, 0, 0, 0.25);
  outline: none;
}

button.large {
  font-size: 26px;
  line-height: 36px;
  padding: 15px 30px 10px 30px;
}

button.medium {
  padding: 12px 25px 12px 25px;
  letter-spacing: 2.5px;
}

button.small {
  padding: 6px 12px 6px 12px;
  font-size: 1em;
}

p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25rem;
}

h1 {
  font-weight: 700;
  color: #000;
  font-size: 32px;
  line-height: 37px;
}

h2 {
  font-weight: 600;
  color: #4abce5;
  font-size: 26px;
  line-height: 31px;
}

h3 {
  font-weight: 600;
  color: #4abce5;
  font-size: 26px;
  line-height: 36px;
}

h4 {
  font-weight: 600;
  color: #4abce5;
  font-size: 19px;
  line-height: 24px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
p {
  color: #143f69;
  margin: 0;
}
/* styles brought over from mui-typography*/
.h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.167;
}
@media (max-width: 600px) {
  .h1 {
    font-size: 1.2rem;
  }
}


@media (max-width: 600px) {
  .h2 {
    font-size: 1.25rem;
  }
}

.h3 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.167;
}
.h4 {
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.235;
}
.h5 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
}
.h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
}

.help-text {
  font-size: 1rem;
  color: #143f69;
  font-weight: 400;
  line-height: 1.5;
}

.field-error-border {
  border: 2px solid red;
}

.small-text {
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400;
}

.no-underline {
  text-decoration: none;
}

.underlined {
  text-decoration: underline;
}

.caption {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
}

/* *********** -- TYPOGRAPHY UTILS -- *********** */

.white-space-normal {
  white-space: normal;
}

.roboto-font {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.bold {
  font-weight: bold;
}

.font-size-14 {
  font-size: 14px;
  line-height: 1.29;
}

.font-size-16 {
  font-size: 16px;
  line-height: 1.5;
}

/* *********** -- UTILS -- *********** */

/* width utils */
.max-width-100 {
  max-width: 100%;
}

.max-width-360 {
  max-width: 360px;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-600 {
  max-width: 600px;
}

/* THESE ARE GREAT FOR INPUTS _ 25 per char */
.width-25 {
  width: 25px;
}

.width-50 {
  width: 50px;
}

.width-100 {
  width: 100px;
}

.width-175 {
  width: 175px;
}

/* color utils */
.dark {
  color: #000;
}

.blue {
  color: blue;
}

.red {
  color: red;
}

.green {
  color: green;
}

.coral {
  color: coral;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #efefef;
}

.bg-error {
  background-color: red;
}

.slate-gray {
  color: slategray;
}

.cornflower-blue {
  color: cornflowerblue;
}

.af-blue {
  color: #4bbce8;
}

.bg-light-navy {
  background-color: #143f69;
}

.af-dark-blue {
  color: #143f69;
}

.af-alt-dark-blue {
  color: #3b5669;
}

.af-alt-blue {
  color: #516e89;
}

.af-mid-gray {
  color: #8e8e8e;
}

.af-secondary-blue {
  color: #286880;
}

.error {
  color: red;
}

.warning {
  color: rgb(255, 186, 69);
}

.opaque-black {
  color: rgba(0, 0, 0, 0.54);
}

.bg-white {
  background-color: #fff;
}

.bg-light-yellow {
  background-color: #faf7f3;
}

/* layout utils */
.z-index-max {
  z-index: 9999999;
}

.position-fixed {
  position: fixed;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.full-width {
  width: 100%;
}

.max-25 {
  max-width: 25px;
}

.max-30 {
  max-width: 30px;
}

.max-45 {
  max-width: 45px;
}

.max-50 {
  max-width: 50px;
}

.max-60 {
  max-width: 60px;
}

.max-95 {
  max-width: 95px;
}

.max-100 {
  max-width: 100px;
}

.max-120 {
  max-width: 120px;
}

.max-200 {
  max-width: 200px;
}

.max-240 {
  max-width: 240px;
}

.max-295 {
  max-width: 295px;
}

.max-320 {
  max-width: 320px;
}

.w-80-percent {
  width: 80%;
}

.width-auto {
  width: auto;
}

.height-auto {
  height: auto;
}

.height-175 {
  height: 175;
}

.max-height-150 {
  max-height: 150;
}

.height-100vh {
  height: 100vh;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-auto {
  flex: auto;
}

.flex-align-center {
  align-items: center;
}

.flex-self-center {
  align-self: center;
}

.flex-content-center {
  align-content: center;
}

.justify-self-center {
  justify-self: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

/* text utils */

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.caption {
  font-size: 11px;
  line-height: 16px;
}

/* container utils */
.uro-curated-container {
  max-width: 800px;
  padding: 16px;
  margin: 0 auto;
}

.paper {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.vertical-middle {
  vertical-align: middle;
}

.mobile-card {
  padding: 40px 40px 60px 40px;
}

.mobile {
  margin: 0 auto;
  width: 400px;
}

.mobile-screen {
  margin: 24px 16px 48px 16px;
}

.screen-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 60px;
}

/* card utils */
.paper-card {
  width: 420px;
  height: 112px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.33);
  border: solid 0.5px #ccc;
  background-color: #fff;
  font-size: 21px;
  font-weight: 300;
  text-transform: none;
}

/* margin utils */
.margin-auto {
  margin: auto;
}

.ma-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-52 {
  margin-bottom: 52px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.ma-12 {
  margin: 12px;
}

.ma-16 {
  margin: 16px;
}

.ma-20 {
  margin: 20px;
}

.ma-24 {
  margin: 24px;
}

.ma-32 {
  margin: 32px;
}

.ma-0-auto {
  margin: 0 auto;
}

.ma-auto {
  margin: auto;
}

.mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mh-0 {
  margin-left: 0;
  margin-right: 0;
}

.mt-12 {
  margin-top: 12px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

/* padding utils */
.pa-8 {
  padding: 8px;
}

.pa-16 {
  padding: 16px;
}

.pa-24 {
  padding: 24px;
}

.pa-32 {
  padding: 32px;
}

.pa-48 {
  padding: 48px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-48 {
  padding-top: 48px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-12 {
  padding-left: 12px;
}

.pl-16 {
  padding-left: 16px;
}

/* padding-vertical */
.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ph-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ph-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ph-18 {
  padding-left: 18px;
  padding-right: 18px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-248 {
  padding-bottom: 248px;
}

/* cursor utils */
.pointer {
  cursor: pointer;
}

/* input utils */

button:disabled {
  border-radius: 16px;
  background-color: #cccccc;
  color: #888888;
}

button.disabled {
  border-radius: 16px;
  background-color: #cccccc;
  color: #888888;
}

/* remove number spinner on webkit */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 16px;
}

/* - custom select */
.select-css {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  color: #000;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: none;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
        
    */
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMy4yNDUgNGwtMTEuMjQ1IDE0LjM3NC0xMS4yMTktMTQuMzc0LS43ODEuNjE5IDEyIDE1LjM4MSAxMi0xNS4zOTEtLjc1NS0uNjA5eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}
/* Focus style */
.select-css:focus {
  outline: none;
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

/* - end custom select */

/* *********** -- DESKTOP DEFAULTS -- *********** */
.desktop-storybook-wrapper {
  min-width: 480px;
  max-width: 1632px;
  margin: 0 auto;
}
.desktop-form-wrapper {
  width: 66%;
}

.divider {
  margin: 0 80px;
}

/* Media Queries */
@media (min-width: 480px) {

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
}
